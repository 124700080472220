var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "benchmarks"
    }
  }, [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Benchmarks",
      "sub-title": "An insight into how you're portfolios and assets are performing against various benchmarks.",
      "category": "Benchmarks",
      "category-logo": "fa-chart-bar",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _c('TabView', {
    attrs: {
      "route-name": "benchmarks",
      "tabs": _vm.tabs,
      "loading": _vm.loading,
      "exact": false
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }