var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "etn-icon-wrapper"
  }, [_c('div', {
    staticClass: "etn-icon mb-2"
  }, [_c('div', {
    staticClass: "left"
  }), _c('div', {
    staticClass: "right"
  })])]);

}]

export { render, staticRenderFns }